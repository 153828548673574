





























import { BasicPaymentViewItem } from "@/types/Payment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import TickIcon from "@/assets/icons/saxcons/tick-circle-linear.svg";
import { SportangoTextField } from "../Inputs/overrides";
import EditIcon from "@/assets/icons/saxcons/edit-2-linear.svg";

@Component({
  name: "editable-amount",
  components: {
    SportangoTextField,
    EditIcon,
    TickIcon
  }
})
export default class EditableAmount extends Vue {
  isEditing = false;
  customAmount!: string;
  @Prop({ required: true })
  item!: BasicPaymentViewItem | null;
  @Prop({ required: false, default: true })
  allowEditing!: boolean;

  @Watch("item")
  setDefaultAmount(newValue: BasicPaymentViewItem | null) {
    if (newValue !== null && newValue !== undefined) {
      this.customAmount = newValue.amount.toString();
    }
  }

  get amountToShow(): number {
    if (this.item) {
      return this.item.amount;
    }
    return 0;
  }

  get disabled(): boolean {
    if (this.item?.isPaymentRunning) {
      return true;
    } else if (
      this.item?.status === "success" ||
      this.item?.status === "processing"
    ) {
      return true;
    }
    return false;
  }

  changeEditing() {
    if (this.allowEditing) {
      this.isEditing = true;
    }
  }

  mounted() {
    this.setDefaultAmount(this.item);
  }

  emitChange() {
    this.isEditing = false;
    this.$emit("updated", this.item?.itemId, Number(this.customAmount));
  }
}
