































import { ProgramPaymentViewItem } from "@/types/Payment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "cash-payment-button"
})
export default class CashPaymentButton extends Vue {
  @Prop({ required: true })
  item!: ProgramPaymentViewItem | null;
  @Prop({ required: false, default: false })
  disabled!: boolean;
  @Prop({ required: false, default: true })
  block!: boolean;
  @Prop({ required: false, default: "Paid by cash" })
  cashUnPayText!: string;
  @Prop({ required: false, default: "Mark as paid" })
  cashPayText!: string;
  @Prop({ required: false, default: "warning" })
  unPaidColor!: string;
  @Prop({ required: false, default: true })
  outline!: boolean;
  @Prop({ required: false, default: false })
  btnText!: boolean;

  get paidInCash(): boolean {
    if (this.item !== null && this.item !== undefined) {
      return this.item.paidInCash || false;
    }
    return false;
  }

  get hideButton(): boolean {
    if (this.item?.status === "success") {
      if (this.paidInCash) {
        return false;
      }
      return true;
    }
    return false;
  }

  get isDisabled(): boolean {
    if (this.item?.status) {
      if (this.item.status === "processing") {
        return true;
      }
      if (this.item.status === "success") {
        return !this.item.paidInCash;
      }
    }
    return false;
  }

  emitClick() {
    this.$emit("cash-click", this.item?.uid, this.item);
  }
}
